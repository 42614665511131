import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Spinner from 'react-spinner-material'
import Sidebar from '../components/sidebar'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Grid, TextField, Button, InputLabel, Select, MenuItem } from '@mui/material'
import validator from 'validator'
import dinnertable from '../assets/images/dinnertable.jpg'
import CheckoutPageOrderItem from '../components/checkoutpageorderitem'
import PageHeader from '../components/pageheader'
import rmm_constants from '../assets/data/constants'
import getStripe from '../components/stripejs'

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'
const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};
class CheckoutPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      valid_name: true,
      valid_mobile: true,
      valid_email: true,      
      viewportWidth: isClient ? window.innerWidth : 0,
      loading: true,      
      orderitems: [],
    }

    this.changeFormValue = this.changeFormValue.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.changeCookie = this.changeCookie.bind(this);
    this.switchPage = this.switchPage.bind(this);
    this.removeitemfromorder = this.removeitemfromorder.bind(this);
  }
  switchPage(pagename){
    this.setState({
        loading: true,
     });
     navigate(pagename)
  };
  changeCookie(cookieName, newState) {
    const { cookies } = this.props
    cookies.set(cookieName, newState, { path: '/' })
    this.setState({ cookieName: newState })
  }
  updateWindowDimensions = () => {
    // console.log(window.innerWidth)
    this.setState({ viewportWidth: window.innerWidth })
  }
  removeitemfromorder(itemtoremove) {    
    var currentOrderItems = this.state.orderitems;
    for(var i = 0; i < currentOrderItems.length; i++){
      if(itemtoremove.menuitem.menuitemid === currentOrderItems[i].menuitem.menuitemid){
        // console.log(itemtoremove)
        currentOrderItems.splice(i, 1);        
      }
    }
    this.setState({
      orderitems: currentOrderItems
    })
    this.changeCookie("orderitems", currentOrderItems)  
  }
  setLoading(loading) {
    this.setState({ loading })
  }
  handleSubmit = async (event) => {
    event.preventDefault()
    this.setLoading(true)

    var valid_name = true
    var valid_email = true
    var valid_mobile = true    
    var valid_inputs = true
    
    if (event.target[0].value.length <= 1) {
      valid_name = false
      valid_inputs = false
    }
    if (event.target[2].value.length <= 1) {
      valid_mobile = false
      valid_inputs = false
    }    
    if (event.target[2].value.replace(/\s+/g, '').length != 10) {
      valid_mobile = false
      valid_inputs = false
    }
    if (!validator.isEmail(String(event.target[4].value))) {
      valid_email = false
      valid_inputs = false
    }    
    var newState = {
      ...this.state,
      valid_name: valid_name,
      valid_mobile: valid_mobile,
      valid_email: valid_email,      
    }
    this.setState(newState)
    
    if (valid_inputs) {            
      this.changeCookie('userdetails', {
        name: event.target[0].value,
        mobile: event.target[2].value.replace(/\s+/g, ''),
        email: String(event.target[4].value)        
      })
      var largeItems = 0;
      var smallItems = 0;
      var priceItems = [];

      for(var i = 0; i < this.state.orderitems.length; i++){
        if(this.state.orderitems[i].size=='large'){ largeItems+=this.state.orderitems[i].qty}
        if(this.state.orderitems[i].size=='small'){ smallItems+=this.state.orderitems[i].qty}          
      }
      if(largeItems > 0) {priceItems.push({ price: rmm_constants.large_item_sku, quantity: largeItems })}
      if(smallItems > 0) {priceItems.push({ price: rmm_constants.small_item_sku, quantity: smallItems })}      
            
      try {
        const stripe = await getStripe()
        var result = await stripe.redirectToCheckout({
          mode: "payment",
          customerEmail: event.target[4].value,
          lineItems: priceItems,
          successUrl: `${window.location.origin}/thankyou`,
          cancelUrl: `${window.location.origin}/orderproblem`,
        })         

        if (result.error) {
          console.warn('Error:', result.error)
          this.setLoading(false)
        } else {
          console.log(result)
        }
      } catch (err) {
        console.log(err)
        navigate("/orderproblem")
        // Need to display an error and get them to try again
      }
      

    } else {
      this.setLoading(false)
    }
  }
  changeFormValue(event) {
    if (event.target.id === 'firstName') {
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          firstName: event.target.value,
        },
      })
    }
    if (event.target.id === 'lastName') {
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          lastName: event.target.value,
        },
      })
    }
    if (event.target.id === 'email') {
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          email: event.target.value,
        },
      })
    }
    if (event.target.id === 'password') {
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          password: event.target.value,
        },
      })
    }
    if (event.target.id === 'confpassword') {
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          confpassword: event.target.value,
        },
      })
    }
    if (event.target.id === 'promocode') {
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          promocode: event.target.value,
        },
      })
    }
    if (event.target.id === 'allowemails') {
      var allowemail = !this.state.signupdetails.allowemails
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          allowemails: allowemail,
        },
      })
    }
    if (event.target.id === 'termsandcond') {
      var termsandcond = !this.state.signupdetails.termsandcond
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          termsandcond: termsandcond,
        },
      })
    }
  }
  componentDidMount() {
    // console.log(this.props.allCookies)
    this.changeCookie('lastpage', '/')
    // if (!this.props.allCookies.firstload) {            
    //   setTimeout(() => {
    //     navigate('/loading')
    //   }, 500)
    // }
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
    if(this.props.allCookies.orderitems) {
      this.setState({
        orderitems: this.props.allCookies.orderitems
        
      })
    }    
    this.setState({ loading: false })
  }
  componentWillUnmount() {
    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    const siteTitle = "Ready Made Meals"
    const keywords = ["ready made meals", "healthy", "dinner", "family", "building futures", "montessori"]
    const pageName = 'Checkout'
    // console.log(this.state.orderitems)
    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }
    var checkoutTotal = 0.0;    
    for(var i = 0; i < this.state.orderitems.length; i++){
      if(this.state.orderitems[i].size == "large"){ checkoutTotal+=(this.state.orderitems[i].qty*rmm_constants.large_item_price)}
      if(this.state.orderitems[i].size == "small"){ checkoutTotal+=(this.state.orderitems[i].qty*rmm_constants.small_item_price)}
    }

    var checkoutPage = (
      <Grid container spacing={0}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Grid item xs={12} className="contentimagecontainer">
                <img className="checkoutimage" src={dinnertable} />
              </Grid>
            </Grid>
            <Grid item xs={6} >
              <Grid item xs={12} style={{paddingTop:"5vmin", paddingBottom:"2.5vmin"}} >
                Please check your order items, then complete the form and click process order to pay.
              </Grid>                    
              <Grid item xs={12}>
                <Grid container spacing={0} className="shoppingcart_items">
                  <Grid item xs={12}>
                    {this.state.orderitems.map((orderitem, index) =>{
                    return (
                      <CheckoutPageOrderItem key={index} orderitem={orderitem} removeitemfromorder={this.removeitemfromorder}/>                          
                      )                          
                    })}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0} className="shoppingcart_totalcontainer">
                  <Grid item xs={10} > Total: </Grid>
                  <Grid item xs={2} className="shoppingcart_total"> { "$ "+checkoutTotal.toFixed(2)} </Grid>
                </Grid>                      
              </Grid>
              
              <Grid item xs={12} style={{paddingTop:"2vmin"}}>
                <form noValidate onSubmit={this.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className="signup_input"
                        autoComplete="name"
                        name="name"
                        variant="outlined"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        onChange={this.changeFormValue}
                      />
                      {!this.state.valid_name ? (
                        <span className="signup_validationmsg">
                          You must enter a valid name.
                        </span>
                      ) : (
                        <span />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className="signup_input"
                        autoComplete="mobile"
                        name="mobile"
                        variant="outlined"
                        required
                        fullWidth
                        id="mobile"
                        label="Mobile No."
                        onChange={this.changeFormValue}
                      />
                      {!this.state.valid_mobile ? (
                        <span className="signup_validationmsg">
                          You must enter a valid mobile number.
                        </span>
                      ) : (
                        <span />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        className="signup_input"
                        autoComplete="email"
                        name="email"
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        onChange={this.changeFormValue}
                      />
                      {!this.state.valid_email ? (
                        <span className="signup_validationmsg">
                          You must enter a valid email.
                        </span>
                      ) : (
                        <span />
                      )}
                      {this.state.email_used ? (
                        <span className="signup_validationmsg">
                          The email you entered has already been signed up.
                        </span>
                      ) : (
                        <span />
                      )}
                    </Grid>                          
                  </Grid>
                  <Grid container spacing={0} style={{paddingTop:"2vmin"}}>
                    <Grid item xs={7}/>
                    <Grid item xs={5} >
                      <Button type="submit" name="complete_order" value="complete_order" className='checkoutbutton' 
                        variant="contained" sx={{ color: '#ffffff', backgroundColor: '#ff4c76' }} >Complete Order </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>                    
            </Grid>                                  
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>

    );
    var checkoutPageMob = (
      <Grid container spacing={0}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container spacing={0}>            
            <Grid item xs={12} >
              <Grid item xs={12} style={{paddingTop:"5vmin", paddingBottom:"2.5vmin"}} >
                Please check your order items, then complete the form and click process order to pay.
              </Grid>                    
              <Grid item xs={12}>
                <Grid container spacing={0} className="shoppingcart_items">
                  <Grid item xs={12}>
                    {this.state.orderitems.map((orderitem, index) =>{
                    return (
                      <CheckoutPageOrderItem key={index} orderitem={orderitem} isMobile={isMobile}
                        removeitemfromorder={this.removeitemfromorder}/>
                      )                          
                    })}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0} className="shoppingcart_totalcontainer">
                  <Grid item xs={7} > Total: </Grid>
                  <Grid item xs={5} className="shoppingcart_total"> { "$ "+checkoutTotal.toFixed(2)} </Grid>
                </Grid>                      
              </Grid>
              
              <Grid item xs={12} style={{paddingTop:"2vmin"}}>
                <form noValidate onSubmit={this.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className="signup_input"
                        autoComplete="name"
                        name="name"
                        variant="outlined"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        onChange={this.changeFormValue}
                      />
                      {!this.state.valid_name ? (
                        <span className="signup_validationmsg">
                          You must enter a valid name.
                        </span>
                      ) : (
                        <span />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className="signup_input"
                        autoComplete="mobile"
                        name="mobile"
                        variant="outlined"
                        required
                        fullWidth
                        id="mobile"
                        label="Mobile No."
                        onChange={this.changeFormValue}
                      />
                      {!this.state.valid_mobile ? (
                        <span className="signup_validationmsg">
                          You must enter a valid mobile number.
                        </span>
                      ) : (
                        <span />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        className="signup_input"
                        autoComplete="email"
                        name="email"
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        onChange={this.changeFormValue}
                      />
                      {!this.state.valid_email ? (
                        <span className="signup_validationmsg">
                          You must enter a valid email.
                        </span>
                      ) : (
                        <span />
                      )}
                      {this.state.email_used ? (
                        <span className="signup_validationmsg">
                          The email you entered has already been signed up.
                        </span>
                      ) : (
                        <span />
                      )}
                    </Grid>                          
                  </Grid>
                  <Grid container spacing={0} style={{paddingTop:"2vmin"}}>
                    <Grid item xs={1}/>
                    <Grid item xs={11} >
                      <Button type="submit" name="complete_order" value="complete_order" className='checkoutbutton' 
                        variant="contained" sx={{ color: '#ffffff', backgroundColor: '#ff4c76' }} >Complete Order </Button>
                    </Grid>
                    
                  </Grid>
                </form>
              </Grid>                    
            </Grid>                                  
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>

    );
    

    return (
      <React.Fragment>
        <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <Sidebar switchPage={this.switchPage} orderitems={this.state.orderitems} removeitemfromorder={this.removeitemfromorder}/>
          {this.state.loading ? (
            <div className="spinneroverlay">
              <div className="spinnercontainer">
                <Spinner
                  radius={100}
                  color={'#ff4c76'}
                  stroke={2}
                  visible={true}
                />
              </div>
            </div>
          ) : (
            <div />
          )}
          
          <div className="page_wrapper generalpage_wrapper">
            <PageHeader pagewidth={pagewidth.matches} pagetitle={pageName} />
            {isMobile ? checkoutPageMob : checkoutPage}
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(CheckoutPage)
