import React from 'react'
import { Link } from 'gatsby'
import { IoIosClose } from "react-icons/io";
import { Grid, Button, InputLabel, Select, MenuItem } from '@mui/material'
import moment from 'moment'
import Spinner from 'react-spinner-material'
import rmm_constants from '../assets/data/constants';

const largeprice = 7.5
const smallprice = 4.5

class CheckoutPageOrderItem extends React.Component {
    constructor(props) {
        super(props)
        this.removeitem = this.removeitem.bind(this);        
    }
    removeitem = (event) => {
        this.props.removeitemfromorder(this.props.orderitem)
    }  
    render() {         
        var displaySize = "Large"
        var displayPrice = (this.props.orderitem.qty*rmm_constants.large_item_price).toFixed(2).toString();
        if(this.props.orderitem.size == "small") { 
            displaySize="Small"
            displayPrice = (this.props.orderitem.qty*rmm_constants.small_item_price).toFixed(2).toString();
        }
        var checkoutpageorderitem = (
            <Grid container spacing={0} className="shoppingcartitem_container">                
                <Grid item xs={11} >
                    <Grid container spacing={0}>                        
                        <Grid item xs={11} className="shoppingcartitem_name">
                            {this.props.orderitem.menuitem.name}
                        </Grid>
                        <Grid item xs={2} className="shoppingcartitem_detail">
                            {displaySize}
                        </Grid>
                        <Grid item xs={2} className="shoppingcartitem_detail">
                            {this.props.orderitem.qty}
                        </Grid>                        
                        <Grid item xs={2} className="shoppingcartitem_detail">
                            {"$ "+displayPrice}
                        </Grid>
                        <Grid item xs={6} />
                        <Grid item xs={12} className="shoppingcartitem_date">
                            {"Pickup: "+moment(this.props.orderitem.deliverytimestamp).format("dddd DD MMM YYYY HH:mm")}
                        </Grid>                        
                    </Grid>                    
                </Grid>
                <Grid item xs={1}>
                    <Button name="removeitem" value={this.props.orderitem.menuitem.menuitemid} onClick={this.removeitem}>
                        <IoIosClose className="shoppingcartitem_removebutton" size={40} />
                    </Button>                                                                             
                </Grid>
            </Grid>
        )
        var checkoutpageorderitemMob = (
            <Grid container spacing={0} className="shoppingcartitem_container">                
                <Grid item xs={10} >
                    <Grid container spacing={0}>                        
                        <Grid item xs={11} className="shoppingcartitem_name">
                            {this.props.orderitem.menuitem.name}
                        </Grid>
                        <Grid item xs={2} className="shoppingcartitem_detail">
                            {displaySize}
                        </Grid>
                        <Grid item xs={2} className="shoppingcartitem_detail">
                            {this.props.orderitem.qty}
                        </Grid>                        
                        <Grid item xs={2} className="shoppingcartitem_detail">
                            {"$ "+displayPrice}
                        </Grid>
                        <Grid item xs={6} />
                        <Grid item xs={12} className="shoppingcartitem_date">
                            {"Pickup: "+moment(this.props.orderitem.deliverytimestamp).format("dddd DD MMM YYYY HH:mm")}
                        </Grid>                        
                    </Grid>                    
                </Grid>
                <Grid item xs={2}>
                    <Button name="removeitem" value={this.props.orderitem.menuitem.menuitemid} onClick={this.removeitem}>
                        <IoIosClose className="shoppingcartitem_removebutton" size={40} />
                    </Button>                                                                             
                </Grid>
            </Grid>
        )

        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    {this.props.isMobile ? checkoutpageorderitemMob : checkoutpageorderitem }
                </Grid>
            </Grid>
            
                
            
            
        )
               
    }
}

export default CheckoutPageOrderItem
